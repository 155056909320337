import React from 'react';
import { connect } from 'react-redux';
import { PartnersImgs } from '../../../../img/index';
// @styles
import styles, { StyledImg } from './Clients.styles';
const { ClientsContainerBox } = styles;

const _LandingClients = ({ t, ...props }) => {
	const dAos = props.locale === 'en' ? 'zoom-in-right' : 'zoom-in-left';
	return (
		<ClientsContainerBox>
			<div class="brand-section pt-11 pb-9 pt-lg-24 pb-lg-24">
				<div class="container">
					<div class="row justify-content-center align-items-center">
						<div class="col-md-10-col-lg-9 col-xl-8">
							<div class="section-title text-center mb-7 mb-lg-11">
								<h2 class="title gr-text-4 mb-9">{t('clients.title')}</h2>
								<p class="gr-text-8 px-lg-8 mb-0">{t('clients.desc')}</p>
							</div>
						</div>
						<div class="col-12 col-md-11 col-lg-10 col-xl-9">
							<div class="brand-logos d-flex justify-content-center align-items-center mx-n6 flex-wrap">
								{PartnersImgs.map((imgSrc, index) => (
									<div
										class="single-brand mx-9 py-7 gr-opacity-8"
										data-aos={dAos}
										data-aos-duration="100"
										data-aos-once="true"
										key={index}
									>
										<StyledImg src={imgSrc} alt="" class="w-100" />
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</ClientsContainerBox>
	);
};

const mapProps = (state) => {
	return {
		locale: state.locale.locale
	};
};

export const LandingClients = connect(mapProps)(_LandingClients);
