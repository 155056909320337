import { createGlobalStyle } from 'styled-components';
import { backgroundColor, color, bodyBackgroundColor } from '@theme'
export const GlobalStyles = createGlobalStyle`
  *,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

    html {
    direction: ltr;

    @include respond(tab-land) { // width < 1200
            font-size: 56.25%; // 1 rem = 9px; 8/16 = 50%
        }

        @include respond(tab-port) { // width < 900
            font-size: 50%; // // 1 rem = 8px; 8/16 = 50%
        }

        @include respond(big-desktop) {
            font-size: 57%; // 1rem = 12; 12/16 = 57%
        }
    }

  body {
    box-sizing: border-box;
    ${'' /* background: #FFF; */}
    color: ${color};
  }`;
