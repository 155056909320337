import styled from 'styled-components'

const ClientsContainerBox = styled.div`
    width: 100vw;
    max-width: 100vw;
    height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #FFF;
    /* background-image: linear-gradient(to right, #FFF, #000); */
`

export const StyledImg = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 51px;
    object-fit: contain;
    border-color: #ccc;
    border-width: 1px;

    &:hover {
        transition: all 1.5s;
		transform: scale(1.3);
        background-color: #f5f5f5;
	}
`

export default {
    ClientsContainerBox,
    StyledImg
}