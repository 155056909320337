import React from 'react'
import styled from 'styled-components'

export const View = ({ children, ...props}) => {
    return (
        <StyledView {...props}>
            {children}
        </StyledView>
    )
}

export const StyledView = styled.div`
    display: flex;
    ${({ border }) => border && `border: 1px solid ${border}`}
    ${({ h }) => h && `height: ${h}`};
    ${({ w }) => w && `width: ${w}`};
    ${({ pdng }) => pdng && `padding: ${pdng}`};
    ${({ mrgn }) => mrgn && `margin: ${mrgn}`};
    ${({ ai }) => ai && `align-items: ${ai}`};
    ${({ ais }) => ais && `align-self: ${ais}`};
    ${({ jc }) => jc && `justify-content: ${jc}`};
    ${({ y }) => `flex-direction: ${y ? 'column' : 'row'}`};
    ${({styles}) => ({...styles})};
`