import {combineReducers} from 'redux';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';



import config from './config/config.reducer'
import locale from './locale/locale.reducer'

const rootReducer = combineReducers({
  config,
  locale
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['locale'],
  blacklist: [],
}

export default persistReducer(persistConfig, rootReducer)
