import React, { useEffect } from 'react';

import { HashRouter, Route, Switch, BrowserRouter } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';
import stylisRTLPlugin from 'stylis-plugin-rtl';
import { connect } from 'react-redux'
import AOS from 'aos';
import 'aos/dist/aos.css';

// @locals
import { GlobalStyles } from '@global';
import ThemeProvider from '@contexts/theme/Theme.provider';

import AppContainer from './AppContainer'

import './App.css';

const loading = (
	<div className="pt-3 text-center">
		<div className="sk-spinner sk-spinner-pulse">
			<h1>Loading ....</h1>
		</div>
	</div>
);
function App(props) {
	let plugins = []
	if(props.locale === 'ar'){
		plugins.push(stylisRTLPlugin)
	}
	return (
		<StyleSheetManager
			stylisPlugins={plugins} // allow incase RTL styles applied (lang === 'ar')
		>
			<ThemeProvider>
				<React.Fragment>
					<GlobalStyles />
					<React.Suspense loading={loading} fallback={false}>
						<AppContainer />
					</React.Suspense>
				</React.Fragment>
			</ThemeProvider>
		</StyleSheetManager>
	);
}

const mapProps = state => {
	return {
		locale: state.locale.locale
	}
}
export default connect(mapProps)(App);
