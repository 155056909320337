import AlbiladSrc from './albilad.jpg'
import DefSrc from './def.jpg'
import DoSrc from './do.jpg'
import DrsSrc from './drs.jpg'
import ElmSrc from './elm.jpg'
import GcSrc from './gc.jpg'
import PayfortSrc from './payfort.jpg'
import StcpaySrc from './stcpay.jpg'
import TransSrc from './trans.jpg'
import UnifoSrc from './unifo.jpg'

export const PartnersImgs = [
    AlbiladSrc,
    DefSrc,
    DoSrc,
    DrsSrc,
    ElmSrc,
    GcSrc,
    PayfortSrc,
    StcpaySrc,
    TransSrc,
    UnifoSrc,
]