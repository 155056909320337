import styled from 'styled-components'

const ServicesContainerBox = styled.div`
    width: 100vw;
    height: unset;
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(#2bd67b, #68d585); */
    /* background-color: #000; */
    /* background-color: red; */

    @media only screen and (max-width: 600px) {
        flex: 1;
        display: block;
        width: 100%;
        height: 100%;
    }
`

const ItemTitle = styled.h3`
    text-align: left;
    height: 40px;
    width: 100%;
    @media only screen and (max-width: 600px) {
        text-align: center;
    }
`

const ServiceContainer = styled.div`
    height: unset;
    @media only screen and (max-width: 600px) {
        height: auto;
    }
`

const ItemDesc = styled.p`
    text-align: left;
    @media only screen and (max-width: 600px) {
        text-align: center;
    }
`



export default {
    ServicesContainerBox,
    ItemTitle,
    ItemDesc,
    ServiceContainer
}