import produce from 'immer';
import { UPDATE_LOCALE_CASE } from './locale.types';

const INITIAL_STATE = {
	locale: 'ar'
};

export default function localeReducer(state = INITIAL_STATE, action) {
	const { type, payload } = action;
	return produce(state, (draft) => {
		switch (type) {
			case UPDATE_LOCALE_CASE: {
				draft.locale = action.payload;
				return draft;
			}

			default:
				return draft;
		}
	});
}
