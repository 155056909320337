import React, { useEffect } from 'react';

// @styles
import styles from './Services.styles';

const { ServicesContainerBox, ItemTitle, ItemDesc, ServiceContainer } = styles;

export const LandingServices = ({t, ...props}) => {

	const services = [
		{
			id: 1,
			title: t('services.t1'),
			desc: t('services.d1')
		},
		{
			id: 2,
			title: t('services.t2'),
			desc: t('services.d2')
		},
		{
			id: 3,
			title: t('services.t3'),
			desc: t('services.d3')
		},
		{
			id: 4,
			title: t('services.t4'),
			desc: t('services.d4')
		},
		{
			id: 5,
			title: t('services.t5'),
			desc: t('services.d5')
		},
		{
			id: 6,
			title: t('services.t6'),
			desc: t('services.d6')
		},
	];

	return (
			<ServicesContainerBox className="hero-area position-relative pt-29 pt-lg-33 pb-15 pb-lg-29">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-xl-6 col-lg-8 col-md-10">
							<div className="section-title text-center mb-11 mb-lg-15">
								<h2 className="title gr-text-4 mb-6">{t('services.title')}</h2>
								{/* <p className="gr-text-8 mb-0 px-lg-7 px-xl-0">
									{t('services.desc')}
								</p> */}
							</div>
						</div>
					</div>
					<div className="row align-items-center justify-content-center">
						{services.map((service, index) => (
							<ServiceContainer className="col-lg-4 col-md-6 col-sm-10 mb-13 mb-lg-0">
								<div className="service-card">
									<ItemTitle className="card-title gr-text-7 mb-7">{service.title}</ItemTitle>
									<div className="img-thumb mb-8">
										{/* <img
											className="rounded-10 w-100"
											src="./image/l3/jpg/l3-service-img1.jpg"
											alt=""
										/> */}
									</div>
									<ItemDesc className="excerpt gr-text-9 mb-6">{service.desc}</ItemDesc>
									{/* <a href="" className="btn-link with-icon gr-text-9 font-weight-bold">
										Learn more <i className="icon icon-tail-right font-weight-bold" />
									</a> */}
								</div>
							</ServiceContainer>
						))}
					</div>
				</div>
			</ServicesContainerBox>
	);
};
