import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { changeLocale } from '@actions';
import i18n from '@langs'

const Localizer = (props) => {
	useEffect(() => {
        handleLangChangeFromPath(props.location.pathname)
		props.history.listen((location) => {
            handleLangChangeFromPath(props.location.pathname)
		});
	}, []);

	const handleLangChangeFromPath = (path) => {
		switch (path) {
			case '/':
			case '/en': {
				props.changeLocale('en');
				i18n.changeLanguage('en')
				break;
			}
			case '/ar': {
				props.changeLocale('ar');
				i18n.changeLanguage('ar')
				break;
			}
			default:
				props.changeLocale(props.locale);
		}
	};

	return props.children;
};

const mapProps = (state) => {
	return {
		locale: state.locale
	};
};
export default withRouter(connect(mapProps, { changeLocale })(Localizer));
