import theme from 'styled-theming';

const system_colors = {

	red: { light: '#f53b30', dark: '#f5453b' },

	orange: { light: '#f89502', dark: '#f99e0c' },

	yellow: { light: '#fccc00', dark: '#fcd60a' },

	green: { light: '#43c759', dark: '#49d74c' },

	teal: { light: '#5ac8fa', dark: '#64d2ff' },

	blue: { light: '#197aff', dark: '#1e84ff' },

	indigo: { light: '#5856d6', dark: '#5e5ce6' },

	purple: { light: '#af52de', dark: '#bf59f2' },

	pink: { light: '#f52c56', dark: '#f53660' },

	grey: { light: '#8e8e93', dark: '#8e8e93' },

	grey2: { light: '#adaeb1', dark: '#636366' },

	grey3: { light: '#c7c7cc', dark: '#48484a' },

	grey4: { light: '#d1d1d6', dark: '#3a3a3b' },

	grey5: { light: '#e5e5ea', dark: '#2c2c2f' },

	grey6: { light: '#f2f2f7', dark: '#1c1c1e' }
};

const backgrounds = {
	primary: { light: '#ffffff', dark: '#000000', elevated: '#1c1c1e' }, // dark was base
	secondary: { light: '#efeff4', dark: '#1c1c1e', elevated: '#2c2c2f' }, // dark was base
	tertiary: { light: '#ffffff', dark: '#2c2c2f', elevated: '#3a3a3b' } // dark was base
}

const grouped_backgrounds = {
	primary: { light: '#efeff4', dark: '#000000', elevated: '#1c1c1e'}, // dark was base
	secondary: { light: '#ffffff', dark: '#1c1c1e', elevated: '#2c2c2f'}, // dark was base
	tertiary: { light: '#efeff4', dark: '#2c2c2f', elevated: '#3a3a3b'}, // dark was base
}

const label_colors = {
	primary: { light: '#000000', dark: '#ffffff' },
	secondary: { light: '#8d8d93', dark: '#8d8d93' },
	tertiary: { light: '#c5c5c7', dark: '#464649' },
	quarternary: { light: '#dcdcdd', dark: '#2a2a2c' }
}

const fill_colors = {
	primary: { light: '#e4e4e6', dark: '#2b2b2d' },
	secondary: { light: '#e9e9eb', dark: '#262628' },
	tertiary: { light: '#efeff0', dark: '#1c1c1e' },
	quarternary: { light: '#f4f4f5', dark: '#151516' }
}

const seprator_colors = {
	noneopaque: { light: '#1d1d20', dark: '#3c3c3f' },
	opaque: { light: '#e5e5ea', dark: '#38383a' },
}

const text_bold_colors = {
	primary: { light: '#0a0a0a', dark: '#f6f6f6'},
	secondary: { light: '#919191', dark: '#9a9a9a'},
	tertiary: { light: '#bebebe', dark: '#666460'},
	quarternary: { light: '#d2d2d2', dark: '#534c47'}, //#62666b
}

const text_regular_colors = {
	primary: { light: '#0a0a09', dark: '#f6f6f6'},
	secondary: { light: '#919191', dark: '#9a9a99'},
	tertiary: { light: '#bebebe', dark: '#686462'},
	quarternary: { light: '#d2d2d2', dark: '#685549'},
}

const text_thin_colors = {
	primary: { light: '#0a0909', dark: '#f6f6f6'},
	secondary: { light: '#919191', dark: '#999797'},
	tertiary: { light: '#828282', dark: '#76695e'},
}

export const backgroundColor = theme.variants('mode', 'variant', {
	primary: backgrounds.primary,
	secondary: backgrounds.secondary,
	tertiary: backgrounds.tertiary,
});
export const bodyBackgroundColor = theme('mode', {
	light: '#ffffff',
	dark: '#000000'
});

export const groupedBackgroundColor = theme.variants('mode', 'variant', {
	primary: grouped_backgrounds.primary,
	secondary: grouped_backgrounds.secondary,
	tertiary: grouped_backgrounds.tertiary,
});


export const sepratorColor = theme.variants('mode', 'variant', {
	opaque: seprator_colors.opaque,
	noneopaque: seprator_colors.noneopaque,
});




export const textBold = theme.variants('mode', 'variant', {
	primary: text_bold_colors.primary,
	secondary: text_bold_colors.secondary,
	tertiary: text_bold_colors.tertiary,
	quarternary: text_bold_colors.quarternary,
});

export const textRegular = theme.variants('mode', 'variant', {
	primary: text_regular_colors.primary,
	secondary: text_regular_colors.secondary,
	tertiary: text_regular_colors.tertiary,
	quarternary: text_regular_colors.quarternary,
});

export const textThin = theme.variants('mode', 'variant', {
	primary: text_thin_colors.primary,
	secondary: text_thin_colors.secondary,
	tertiary: text_thin_colors.tertiary,
});



export const borderColor = theme.variants('mode', 'variant', {
	primary: fill_colors.primary,
	secondary: fill_colors.secondary,
	tertiary: fill_colors.tertiary,
	quarternary: fill_colors.quarternary,
});

export const labelColor = theme.variants('mode', 'variant', {
	primary: label_colors.primary,
	secondary: label_colors.secondary,
	tertiary: label_colors.tertiary,
	quarternary: label_colors.quarternary,
});


export const color = theme.variants('mode', 'variant', {
	red: system_colors.red,
	orange: system_colors.orange,
	yellow: system_colors.yellow,
	green: system_colors.green,
	teal: system_colors.teal,
	blue: system_colors.blue,
	indigo: system_colors.indigo,
	purple: system_colors.purple,
	pink: system_colors.pink,
	grey: system_colors.grey,
	grey2: system_colors.grey2,
	grey3: system_colors.grey3,
	grey4: system_colors.grey4,
	grey5: system_colors.grey5,
	grey6: system_colors.grey6,
});





