import styled from 'styled-components'

const StoryContainerBox = styled.div`
    height: unset;
    width: 100vw;
    background-color: #FFF;
    margin: 0;
    padding: 0;
    display: block;
    clear: both;
    @media only screen and (max-width: 600px) {
        height: 100%;
        width: 100%;
        align-items: center;
    }
`

const HeaderStory = styled.h2`
    text-align: left;
    @media only screen and (max-width: 600px) {
        text-align: center;
    }
`

const ItemDesc = styled.p`
    text-align: left;
    @media only screen and (max-width: 600px) {
        text-align: center;
    }
`

export default {
    StoryContainerBox,
    HeaderStory,
    ItemDesc
}