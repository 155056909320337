import React from 'react';

import styled, { ThemeProvider } from 'styled-components';

import ThemeContext from './Theme.context';

import { backgroundColor } from '@theme';
import useDarkMode from '@hooks/useDarkMode';

const ThemeContextProvider = (props) => {
	const [ theme, toggleTheme, componentMounted ] = useDarkMode();
	// const themeMode = theme === 'light' ? { mode: 'light' } : darkTheme;

	if (!componentMounted) {
		return <div />;
	}

	return (
		<ThemeProvider isLight={theme === 'light'} theme={{ mode: theme }}>
			<ThemeContext.Provider
				value={{
					theme,
					toggleTheme
				}}
			>
			<StyledWrapper variant={'secondary'}>
					{props.children}
			</StyledWrapper>
			</ThemeContext.Provider>
		</ThemeProvider>
	);
};

const StyledWrapper = styled.div`
	background-color: ${backgroundColor};
  transition: all 1s;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
`;

export default ThemeContextProvider;
