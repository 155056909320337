import React from 'react';

// @styles
import styles from './Stats.styles';
import { connect } from 'react-redux';

const { StatsContainerBox, ItemTitle, ItemDesc } = styles
const _LandingStats = ({t, ...props}) => {
	return (
		<StatsContainerBox className="content-section pt-12 pb-10 bg-default-6 py-xl-25 ">
			<div className="container">
				<div className="row align-items-center justify-content-md-center dark-mode-texts">
					<div className="col-lg-4 col-md-10">
						<div className="content-text text-center text-lg-left mb-9 mb-lg-0 pr-xl-11">
							<ItemTitle className="title gr-text-5 mb-8">{t('stats.title')}</ItemTitle>
							<ItemDesc className="gr-text-8 mb-9">
								{t('stats.desc')}
							</ItemDesc>
							{/* <a style={{ backgroundColor: '#FFF', color: '#48c07d' }} href="" className="btn gr-hover-y">
								Learn how we work
							</a> */}
						</div>
					</div>
					<div className="col-lg-4 col-md-6">
						<div className="content-img rounded-8">
							<img className="w-100 rounded-8" src="./image/l3/jpg/soc_view.jpeg" alt="" />
						</div>
					</div>
					<div className="col-lg-4 col-md-6">
						<div className="stats-wrapper mt-6 mt-md-0 pl-xl-9">
							<div
								className="single-stat"
								data-aos={props.locale === 'en' ? "fade-right" : 'fade-left'}
								data-aos-duration="800"
								data-aos-once="true"
							>
								<ItemTitle 
									className="title gr-text-5 mb-5"
								>
									{t('stats.m')}</ItemTitle>
								<ItemDesc 
									className="gr-text-8 mb-0"
								>
									{t('stats.p1')}
								</ItemDesc>
							</div>
							<div
								className="single-stat py-6"
								data-aos={props.locale === 'en' ? "fade-right" : 'fade-left'}
								data-aos-duration="1200"
								data-aos-once="true"
							>
								<ItemTitle className="title gr-text-5 mb-5">
									<span className="counter">92</span>%
								</ItemTitle>
								<ItemDesc className="gr-text-8 mb-0">{t('stats.p2')}</ItemDesc>
							</div>
							<div
								className="single-stat py-6"
								data-aos={props.locale === 'en' ? "fade-right" : 'fade-left'}
								data-aos-duration="1800"
								data-aos-once="true"
							>
								<ItemTitle className="title gr-text-5 mb-5">
									<span className="counter">4.9</span>/5.0
								</ItemTitle>
								<ItemDesc className="gr-text-8 mb-0">{t('stats.p3')}</ItemDesc>
							</div>
						</div>
					</div>
				</div>
			</div>
		</StatsContainerBox>
	);
};

const mapProps = state => {
	return {
		locale: state.locale.locale
	}
}
export const LandingStats = connect(mapProps)(_LandingStats)