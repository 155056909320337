import styled from 'styled-components'

const FormContainerBox = styled.div`
    width: 100vw;
    height: unset;
    display: flex;
    background-color: #f4f7fa;
    flex-direction: column;
    justify-content: center;

    @media only screen and (max-width: 600px) {
        flex: 1;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`

const ItemLabel = styled.label`
    text-align: left;
    align-self: flex-start;
    display: flex;
`

const ItemDesc = styled.p`
    text-align: left;
`

export default {
    FormContainerBox,
    ItemLabel,
    ItemDesc
}