import React from 'react';

// @styles
import styles from './Story.styles';

const { StoryContainerBox, HeaderStory, ItemDesc } = styles;
export const LandingStory = ({ t, ...props }) => {
	return (
		<StoryContainerBox id="#story">
			<div class="inner-banner">
				<div class="container">
					<div class="row  justify-content-center mt-md-6 pt-24 pt-lg-29">
						<div class="col-xl-8 col-lg-9">
							<div class="px-md-12 text-center mb-11 mb-lg-14">
								<h2 class="title gr-text-2 mb-9 mb-lg-12">{t('story.title')}</h2>
								<p class="gr-text-8 mb-0">{t('story.desc')}</p>
							</div>
						</div>
						{/* <div class="col-12">
							<div class="banner-fluid-image pt-lg-9">
								<img src="./image/inner/about-banner.png" alt="" class="w-100" />
							</div>
						</div> */}
					</div>
				</div>
			</div>
			<div class="about-content pt-lg-28 pt-13 pb-13 pb-lg-25">
				<div class="container">
					<div class="row">
						<div class="col-lg-6 mb-7 mb-lg-0">
							<div class="pr-xl-13">
								<HeaderStory className="gr-text-3 mb-0">{t('story.header')}</HeaderStory>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="pr-xl-13">
								<ItemDesc class="gr-text-8 mb-7 mb-lg-10">{t('story.p1')} </ItemDesc>
								<ItemDesc class="gr-text-8 mb-0">{t('story.p2')}</ItemDesc>
							</div>
						</div>
					</div>
				</div>
			</div>
		</StoryContainerBox>
	);
};
