import React from 'react';

import { connect } from 'react-redux';
// @locals
import sairLogoSrc from '@imgs/logos/logo_sair.jpeg';
import { ReactComponent as SocLogo } from '@imgs/logos/logoFull.svg';

// @styles
import styles from './Header.styles';
const { SairLogo, LinkHeader, Link, HeaderContainerBox, LocaleLink, LogoLink } = styles;

const links = [
	{ id: '#hero', path: '#hero', name: 'home' },
	{ id: '#story', path: '#story', name: 'story' },
	{ id: '#services', path: '#services', name: 'services' },
	{ id: '#clients', path: '#clients', name: 'partners' },
	{ id: '#stats', path: '#stats', name: 'stats' },
	// { id: '#team', path: '#team', name: 'team' },
	{ id: '#contact', path: '#contact', name: 'contact' }
];
const _LandingHeader = ({ currentPage, t, onClick, ...props }) => {
	return (
		<HeaderContainerBox className="site-header site-header--menu-center site-header--absolute">
			<div class="container-fluid pr-lg--30 pl-lg--30">
				<nav class="navbar site-navbar offcanvas-active navbar-expand-lg  px-0">
					<div class="brand-logo">
						<LogoLink href="/">
							{/* <SairLogo src={sairLogoSrc} /> */}
							<SocLogo width={80} height={'auto'} />
						</LogoLink>
					</div>
					<div class="collapse navbar-collapse" id="mobile-menu14">
						<div>
							<ul class="navbar-nav main-menu">
								{/* <li class="nav-item dropdown">
									<a
										class="nav-link dropdown-toggle gr-toggle-arrow"
										id="navbarDropdown2"
										href="#features"
										role="button"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										Pages <i class="icon icon-small-down" />
									</a>
									<ul class="gr-menu-dropdown dropdown-menu" aria-labelledby="navbarDropdown2">
										<li class="drop-menu-item">
											<a href="about.html">About us</a>
										</li>
									</ul>
								</li> */}

								{links.map((link, index) => {
									return (
										<LinkHeader
											onClick={() => onClick(index)}
											active={link.id === `#${currentPage}`}
											data-menuanchor={link.id}
											class="nav-item"
										>
											<Link
												className="nav-link"
												role="button"
												aria-expanded="false"
												active={link.id === `#${currentPage}`}
											>
												{t(`header.${link.name}`)}
											</Link>
										</LinkHeader>
									);
								})}
							</ul>
						</div>
						<button
							class="d-block d-lg-none offcanvas-btn-close"
							type="button"
							data-toggle="collapse"
							data-target="#mobile-menu14"
							aria-controls="mobile-menu14"
							aria-expanded="true"
							aria-label="Toggle navigation"
						>
							<i class="gr-cross-icon" />
						</button>
					</div>

					<div>
						{props.locale === 'ar' && (
							<LocaleLink className="nav-link" href="/en">
								English
							</LocaleLink>
						)}
						{props.locale === 'en' && (
							<LocaleLink className="nav-link" href="/ar">
								{'العربية'}
							</LocaleLink>
						)}
					</div>

					<button
						class="navbar-toggler btn-close-off-canvas  hamburger-icon border-0"
						type="button"
						data-toggle="collapse"
						data-target="#mobile-menu14"
						aria-controls="mobile-menu14"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<div class="hamburger hamburger--squeeze js-hamburger">
							<div class="hamburger-box">
								<div style={{ fontSize: '19px' }} class="hamburger-inner" />
							</div>
						</div>
					</button>
				</nav>
			</div>
		</HeaderContainerBox>
	);
};

const mapProps = (state) => {
	return {
		locale: state.locale.locale
	};
};
export const LandingHeader = connect(mapProps)(_LandingHeader);
