const ar = {
    header: {
        home: 'الرئيسية',
        story: 'قصتنا',
        services: 'خدماتنا',
        partners: 'شركاؤنا',
        stats: 'احصائيات',
        team: 'الفريق',
        contact: 'تواصل معنا'
    },
    hero: {
        title: 'نسعى لنكون رواد في مجال التقنية، يقودنا طموحنا نحو التفوق والريادة.',
        desc: 'نقدم لعملائنا حلول فريدة ومبتكرة في تطوير البرامج و تطبيقات الموبايل والمنصات الرقمية ،  والدعم الفني ، التسويق الإلكتروني، التجارة الالكترونية ، تصميم مواقع الإنترنت وتطوير المواقع.',
        watch: 'شاهد الفيديو'
    },
    story: {
        title: 'قصتنا',
        desc: 'شركة الخيار الآمن هي شركة سعودية متخصصة في تقنية المعلومات وتطوير تطبيقات الموبايل ومواقع الويب، تأسست في ٢٠١٦ نقدم مجموعة كبيرة من حلولنا الرقمية في العديد من المجالات، من خلال نماذج عمل متنوعة ومرنة تتناسب مع رغبة عملائنا وفق ما يحقق رغباتهم ويحقق طموحاتهم.',
        p1: 'نحن نشارك الاتجاهات والاستراتيجيات المشتركة لتحسين دخل الإيجار والتأكد من البقاء في ارتفاع الطلب على الخدمة. ',
        p2: 'نقوم بتحويل استراتيجياتك إلى أنظمة برمجية وعمليات تقنية تعمل على تحسين أداء عملك.',
        header: 'نحن هنا لمساعدة العملاء للحصول على نجاحهم.'
    },
    services: {
        title: 'الخدمات التي نقدمها لك',
        desc: 'مع الكثير من الكتل الفريدة ، يمكنك بسهولة إنشاء صفحة بدون ترميز. قم ببناء صفحتك المقصودة التالية.',
        t1: 'تصميم واجهات المستخدم للمنتجات الرقمية',
        d1: 'نقوم بانشاء واجهات مستخدم احترافية وسهلة الاستخدام للعميل وذات جودة عالية',
        t2: 'تصميم وتحليل تجربة المستخدم',
        d2: 'مصممينا والمهندسين في فريقنا لديهم طريقة في ايجاد حلول لمشكلتك وسوف يستخدمون أفضل الطرق في تطوير مشروعك وانشاء تجربة مستخدم استثنائية لعملائك',
        t3: 'تطوير تطبيقات الموبايل',
        d3: 'نقوم بتصميم وتطوير تطبيقات تجذب العملاء والجمهور المستهدف ويعمل فريقنا لإنشاء تطبيقات بأعلى جودة وتخدم جميع العملاء بمختلف الاجهزة والنظم الحديثة',
        t4: 'تطوير مواقع الويب',
        d4: 'نوفر خدمات محترفة في تطوير المواقع ونعمل على انشاء تصاميم احترافية لضمان جذب أكبر عدد من العملاء لموقعك',
        t5: 'التسويق الرقمي / ادارة المحتوى',
        d5: 'نوفر خدمات محترفة في تطوير المواقع ونعمل على انشاء تصاميم احترافية لضمان جذب أكبر عدد من العملاء لموقعك',
        t6: 'دعم فني تقني ٧\٢٤',
        d6: 'نوفر خدمات محترفة في تطوير المواقع ونعمل على انشاء تصاميم احترافية لضمان جذب أكبر عدد من العملاء لموقعك',
    },
    clients: {
        title: 'الشركات الكبرى هنا',
        desc: 'تساعد شركتنا أي عملاء في أي مرحلة من مراحل تطوير الأعمال أو البرامج-من الشركات الناشئة في مرحلة التفكير إلى الشركات متوسطة الحجم والمؤسسات الكبيرة مع التركيز على التحسين والتوسع بغض النظر عن الصناعة التي يعملون فيها',
    },
    stats: {
        title: 'يقدم الخبراء ذوو الخبرة نصائح.',
        desc: 'قمنا بتسليم منصة لجمع معلومات سلوك المستخدم ، وبناء نماذج تنبؤية والمساعدة في اتخاذ قرارات تجارية مستنيرة.',
        p1: 'يزورنا العملاء كل شهر لإنجاز خدمتهم عبر موقعنا.',
        p2: 'معدل الرضا يأتي من عملائنا',
        p3: 'متوسط تصنيفات العملاء لدينا',
        m: '+ ١٠٠ الف',
    },
    team: {
        title: 'تعرف على فريقنا',
        desc: 'مع الكثير من الكتل الفريدة ، يمكنك بسهولة إنشاء صفحة بدون ترميز. بناء موقع الاستشارات الخاص بك المقبل في غضون بضع دقائق.',
        m1: 'رائد حباب',
        mr1: 'الرئيس التنفيذي والمؤسس',
        m2: 'فيصل',
        mr2: 'مدير',
        m3: 'مالك',
        mr3: 'مدير المشاريع',
        m4: 'محمد على',
        mr4: 'مدير',
    },
    contact: {
        title: 'تواصل معنا',
        desc: "أسئلتك وتعليقاتك مهمة بالنسبة لنا ، سنكون سعداء للإجابة على أسئلتك أو يمكنك الوصول إلينا عن طريق الهاتف أو البريد الإلكتروني.\n لدينا كل شيء مغطى لاحتياجاتك ",
        visit: 'موقعنا',
        address: 'الامير تركي بن عبدالعزيز الاول ، حطين',
        email: 'راسلنا',
        call: 'اتصل بنا',
    },
    form: {
        f_l_name: 'الاسم الاول والاخير',
        f_l_name_h: 'مثلا محمد أحمد',
        email: 'البريد الإلكترونى',
        email_h: 'مثلا info@sair.sa',
        mobile: 'رقم الجوال',
        mobile_h: 'مثلا +966567890123',
        subj: 'الموضوع',
        subj_h: 'مثلا استفسار حول ...',
        msg: 'الرسالة',
        msg_h: 'مثلا كنت اود ان ...',
        send: 'إرسال'
    },
    footer: {
        desc: 'شركة خيار الأمان هي شركة استشارية للإدارة والتكنولوجيا تساعد الشركات على إجراء تحسينات من خلال التكنولوجيا-خاصة في مجال برامج إدارة الأعمال.',
        comp: 'الشركة',
        about: 'عن الشركة',
        contact: 'تواصل معنا',
        carrers: 'الوظائف',
        press: 'اضغط',
        product: 'المنتج',
        features: 'الميزات',
        pricing: 'الاسعار',
        news: 'الاخبار',
        help: 'مكتب المساعدة',
        support: 'الدعم',
        services: 'الخدمات',
        digital: 'التسويق الرقمي',
        content: 'كتابة المحتوى',
        conent: 'كتابة المحتوى',
        seo: 'سيو للأعمال',
        ui: 'تصميم واجهة المستخدم',
        legal: 'قانوني',
        privacy: 'سياسة الخصوصية',
        terms: 'الشروط والأحكام',
        return: 'سياسة الإرجاع',
    }
}

export default ar;