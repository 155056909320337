import React from 'react';

// @styles
import styles from './Footer.styles';

const { FooterContainerBox, SairLogo, ItemDesc, ItemLink } = styles;
export const LandingFooter = ({ t, ...props }) => {
	return (
		<FooterContainerBox>
			<div className="footer-section pt-13 pt-lg-25 pb-lg-19 ">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-3 col-md-4">
							<div className="footer-logo mb-11">
								<SairLogo />
							</div>
							<ItemDesc className="gr-text-11">{t('footer.desc')}</ItemDesc>
							<ul style={{ display: 'flex', alignItems: 'flex-start' }} className="social-icons py-7 list-unstyled">
								<li>
									<ItemLink href="https://twitter.com/Sair_App" className="gr-text-color gr-hover-text-blue">
										<i className="icon icon-logo-twitter" />
									</ItemLink>
								</li>
								<li>
									<ItemLink href="https://twitter.com/Sair_App" className="gr-text-color gr-hover-text-blue">
										<i className="icon icon-logo-facebook" />
									</ItemLink>
								</li>
								<li>
									<ItemLink href="https://twitter.com/Sair_App" className="gr-text-color gr-hover-text-blue">
										<i className="icon icon-instant-camera-2" />
									</ItemLink>
								</li>
								<li>
									<ItemLink href="https://twitter.com/Sair_App" className="gr-text-color gr-hover-text-blue">
										<i className="icon icon-logo-linkedin" />
									</ItemLink>
								</li>
							</ul>
						</div>
						<div className="col-lg-8 offset-lg-1 col-md-8">
							<div className="row">
								<div className="col-xs-6 col-lg-3">
									<div className="single-footer mb-13 mb-lg-9">
										<ItemDesc className="footer-title gr-text-11">{t('footer.comp')}</ItemDesc>
										<ul className="footer-list list-unstyled gr-text-9">
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.about')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.contact')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.carrers')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.press')}
												</ItemLink>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-xs-6 col-lg-3">
									<div className="single-footer mb-13 mb-lg-9">
										<ItemDesc className="footer-title gr-text-11">{t('footer.product')}</ItemDesc>
										<ul className="footer-list list-unstyled gr-text-9">
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.features')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.pricing')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.news')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.help')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.support')}
												</ItemLink>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-xs-6 col-lg-3">
									<div className="single-footer mb-13 mb-lg-9">
										<ItemDesc className="footer-title gr-text-11">{t('footer.services')}</ItemDesc>
										<ul className="footer-list list-unstyled gr-text-9">
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.digital')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.content')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.seo')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.ui')}
												</ItemLink>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-xs-6 col-lg-3">
									<div className="single-footer mb-13 mb-lg-9">
										<ItemDesc className="footer-title gr-text-11">{t('footer.legal')}</ItemDesc>
										<ul className="footer-list list-unstyled gr-text-9">
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.privacy')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.terms')}
												</ItemLink>
											</li>
											<li className="py-2">
												<ItemLink href="" className="gr-text-color gr-hover-text-blue">
													{t('footer.return')}
												</ItemLink>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</FooterContainerBox>
	);
};
