import styled from 'styled-components';

const TeamContainerBox = styled.div`
    width: 100vw;
    height: unset;
    /* max-height: 100vh; */
    padding-top: 75px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* background-image: linear-gradient(to right, #2bd67b, #68d585); */

    @media only screen and (max-width: 600px) {
        flex: 1;
        display: block;
        width: 100%;
        height: 100%;
    }
`;

const ImageTeamMember = styled.img`
    flex: 1;
    height: 250px;
    object-fit: contain;
    display: flex;
    align-self: flex-start;
    @media only screen and (max-width: 600px) {
        display: flex;
        align-self: center;
        width: 100;
        margin: 0 auto;
    }
`

const ItemTitle = styled.h3`
    text-align: left;
    @media only screen and (max-width: 600px) {
        text-align: center;
    }
`

const ItemDesc = styled.p`
    text-align: left;
    @media only screen and (max-width: 600px) {
        text-align: center;
    }
`

export default {
    TeamContainerBox,
    ImageTeamMember,
    ItemTitle,
    ItemDesc
}
