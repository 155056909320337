import styled from 'styled-components'

import { ReactComponent as LogoIcon } from '@svg/logo.svg'
import sairLogoSrc from '@imgs/logos/logo_sair.jpeg'

const SairLogo = styled(LogoIcon)`
    width: 250px;
    max-height: 2.5vh;
    object-fit: contain;
    @media only screen and (max-width: 600px) {
        width: 150px;
        display: flex;
        align-self: flex-start;
    }
`

const LogoLink = styled.a`
    text-align: left;
    padding: 0 20px;
    @media only screen and (max-width: 600px) {
        text-align: center;
    }
`
// const SairLogo = styled(LogoIcon)`
//     width: 250px;
//     max-height: 3.5vh;
//     object-fit: contain;
// `

const HeaderContainerBox = styled.header`
    background-image: linear-gradient(to right, #FFF, #FFF);
    box-shadow: 0 0 1px #FFF;
    width: 100vw;
    position: fixed;
    top: 0;
    transition: all 1s;
    justify-content: flex-start;
    align-self: flex-start;
    text-align: left;

    @media only screen and (max-width: 600px) {
        /* padding: 11px; */
    }
`

const LinkHeader = styled.li`
    ${({ active }) => active && `
        background-color: #09a8ac;
        border-radius: 5px;
        /* width: 150px; */
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
    `}
`

const Link = styled.a`
    ${({ active }) => active && `
        color: #fff!important;
    `}
`

const LocaleLink = styled.a`
    color: #000;
    margin-inline-end: 15px;
    &:hover {
        color: #0fa8a8;
    }
`

export default { 
    SairLogo,
    HeaderContainerBox,
    LinkHeader,
    Link,
    LocaleLink,
    LogoLink
 }