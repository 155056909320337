import styled from 'styled-components'

const StatsContainerBox = styled.div`
    width: 100vw;
    max-width: 100vw;
    height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #50a4a7;
    /* #09a8ac, #28b485 */
    background-image: linear-gradient(45deg, #09a8ac 25%, transparent 25%, transparent 50%, #50a4a7 50%, #09a8ac 75%, transparent 75%, transparent);

    @media only screen and (max-width: 600px) {
        flex: 1;
        display: block;
        height: 100%;
        max-width: 100%;
    }
`

const ItemTitle = styled.h3`
    text-align: left;
    @media only screen and (max-width: 600px) {
        text-align: center;
    }
`

const ItemDesc = styled.p`
    text-align: left;
    @media only screen and (max-width: 600px) {
        text-align: center;
    }
`

export default {
    StatsContainerBox,
    ItemTitle,
    ItemDesc
}