export const screen = (breakpoint) => (content) => {
  if(breakpoint === "phone") {
    return `@media only screen and (max-width: 37.5em) { ${(content)}; }` //  // 600px
  }

  if(breakpoint === "tab-port") {
    return `@media only screen and (max-width: 56.25em) { ${content}; }` // // 900px
  }

  if(breakpoint === "tab-land") {
    return `@media only screen and (max-width: 75em) { ${content}; }` // 1200px
  }

  if(breakpoint === "big-desktop") {
    return `@media only screen and (min-width: 112.5em) { ${content}; }` // > 1200
  }
}
