import React from 'react';

import Particles from 'react-tsparticles';

// @styles
import styles from './Hero.styles';
import { ReactComponent as PhoneIcon } from '@svg/phone.svg'
import { ReactComponent as SmileIcon } from '@svg/smile.svg'
import {Spacer} from '@comps'

const { HeroContainerBox, ImageBox, BannerImage, Image } = styles;
export const LandingHero = ({t, ...props}) => {
	const width = window.innerWidth
	return (
		<HeroContainerBox id="#hero" className="hero-area position-relative pt-29 pt-lg-33 pb-15 pb-lg-29">
			<div
				style={{
					zIndex: 0,
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					width: '100%',
					height: '100vh'
				}}
			>
				<Particles
					params={{
						particles: {
							number: {
								value:  width > 1000 ? 150 : Math.floor(width/7)
							},
							size: {
								value: 0.5
							}
						},
						interactivity: {
							events: {
								onhover: {
									enable: true,
									mode: 'repulse'
								}
							}
						}
					}}
				/>
			</div>

			<div class="hero-area position-relative">
				<div class="container">
					<div style={{ justifyContent: 'center' }} class="row dark-mode-texts">
						<div
							class="col-xl-8 col-lg-9 col-md-11"
							data-aos="zoom-in"
							data-aos-duration="500"
							data-aos-once="true"
						>
							<div class="hero-content text-center">
								
								<h1 class="title gr-text-2 mb-8">{t('hero.title')}</h1>
								
								<p class="gr-text-8 mb-10 px-lg-11 px-xl-15">
									{t('hero.desc')}
								</p>

								<Spacer y={7}/>

								<div className='hero-img mb-12 row align-items-center' style={{ justifyContent: 'space-around' }} >
									{/* <PhoneIcon fill={'transparent'} /> */}
									<SmileIcon fill={'transparent'} />
								</div>
								<Spacer y={7}/>
								<a
									data-fancybox
									href="https://www.youtube.com/watch?v=rpb3yeTbHv4"
									class="video-link gr-text-color"
								>
									<span class="d-inline-flex mr-lg-3 gr-text-color gr-text-12">
										<i class="far fa-play-circle gr-text-11" />
									</span>
									<Spacer x={.1}/>
									<span class="gr-text-12 font-weight-bold text-uppercase">{t('hero.watch')}</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</HeroContainerBox>
	);
};
