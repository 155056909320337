import styled from 'styled-components'

import { ReactComponent as LogoIcon } from '@svg/logo.svg'

const FooterContainerBox = styled.div`
    width: 100vw;
    height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    /* #09a8ac, #28b485 */
    /* background-image: linear-gradient(#09a8ac, #28b485, #09a8ac); */
    background-color: #fbf5f5;
    @media only screen and (max-width: 600px) {
        flex: 1;
        display: flex;
        text-align: center;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
`

const SairLogo = styled(LogoIcon)`
    /* width: 250px; */
    max-height: 3.5vh;
    object-fit: contain;
    fill: #FFF;
    color: #FFF;
    display: flex;
    align-self: center;
    @media only screen and (max-width: 600px) {
        align-self: flex-start;
    }
`

const ItemLink = styled.a`
    text-align: left;
    display: flex;
    align-self: flex-start;
    margin: 0!important;
    margin: 0 10px!important;
    padding: 0;

    &i {
        margin: 0;
    }
`
const ItemDesc = styled.p`
    text-align: left;
`

export default {
    SairLogo,
    FooterContainerBox,
    ItemLink,
    ItemDesc
}