import styled from 'styled-components'

const ContainerBox = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    min-height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    transition: all 5s;
    /* border: 12px solid #ccc; */
    scroll-behavior: smooth;
`

export default { ContainerBox }