import React from 'react';

import {Spacer} from '@comps'
// @styles
import styles from './Form.styles';

const { FormContainerBox, ItemLabel, ItemDesc } = styles;
export const LandingForm = ({t, ...props}) => {
	return (
		<FormContainerBox className="bg-default-2">
			<div className="container">
				<div className="col-12">
					<div className="login-form bg-white border-gray-3 px-8 pt-8 pb-9 px-sm-11 py-sm-11 shadow-1 rounded-10">
						<form action="./">
							<div className="row">
								<div className="col-md-6">
									<div className="form-group mb-7">
										<ItemLabel for="name" className="gr-text-11 font-weight-bold text-blackish-blue">
											{t('form.f_l_name')}
										</ItemLabel>
										<input
											className="form-control gr-text-11 border"
											type="text"
											id="name"
											placeholder={t('form.f_l_name_h')}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group mb-7">
										<ItemLabel for="email" className="gr-text-11 font-weight-bold text-blackish-blue">
										{t('form.email')}
										</ItemLabel>
										<input
											className="form-control gr-text-11 border"
											type="text"
											id="email"
											placeholder={t('form.email_h')}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group mb-7">
										<ItemLabel for="phone" className="gr-text-11 font-weight-bold text-blackish-blue">
											{t('form.mobile')}
										</ItemLabel>
										<input
											className="form-control gr-text-11 border"
											type="text"
											id="phone"
											placeholder={t('form.mobile_h')}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group mb-7">
										<ItemLabel for="subject" className="gr-text-11 font-weight-bold text-blackish-blue">
											{t('form.subj')}
										</ItemLabel>
										<input
											className="form-control gr-text-11 border"
											type="text"
											id="subject"
											placeholder={t('form.subj_h')}
										/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group mb-7">
										<ItemLabel for="message" className="gr-text-11 font-weight-bold text-blackish-blue">
											{t('form.msg')}
										</ItemLabel>
										<textarea
											name="textblock"
											id="message"
											className="form-control gr-text-11 border-gray-3 gr-textarea-height"
											placeholder={t('form.msg_h')}
										/>
									</div>
								</div>
								<div className="col-xs-3">
									<div className="form-group button-block mt-3 ">
										<button className="form-btn btn btn-primary w-100">{t('form.send')}</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</FormContainerBox>
	);
};
