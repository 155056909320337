import React, { useState, useEffect, useRef } from 'react';

import ReactFullpage from '@fullpage/react-fullpage';
import AOS from 'aos';
import Scrollspy from 'react-scrollspy';
// block components
import { Header, Hero, Story, Services, Clients, Stats, Team, Contact, Form, Footer } from './comps';

// @styled
import styles from './Landing.styles';

const { ContainerBox } = styles;

// Full page
// need later
const SEL = 'ctm-flp';
const SECTION_SEL = `.${SEL}`;
const originalColors = [
	'transparent',
	'transparent',
	'transparent',
	'transparent',
	'transparent',
	'transparent',
	'transparent',
	'transparent'
];
const sectionsColor = [ ...originalColors ];
// NOTE: if using fullpage extensions/plugins put them here and pass it as props.
// This is no longer required for the scrollOverflow option.
const pluginWrapper = () => {
	/*
    * require('./fullpage.fadingEffect.min'); // Optional. Required when using the "fadingEffect" extension.
    */
};
const _fullpages = [
	{
		text: 'Section 1'
	},
	{
		text: 'Section 2'
	},
	{
		text: 'Section 3'
	}
];
export const LandingPage = ({t, ...props}) => {
	const [ setsectionsColor, setSectionsColor ] = useState([ ...originalColors ]);
	const [ fullpages, setFullpages ] = useState(_fullpages);
	const [ currentPage, setCurrentPage ] = useState('hero');
	const fullPageApiRef = useRef()

	

	useEffect(() => {
		AOS.init();
		AOS.refresh();
		window.addEventListener('load', AOS.refresh);
	}, []);

	const onClick = (link) => {
		// console.log('@link', link)
		// console.log('@fullPageApiRef', fullPageApiRef.current)
		fullPageApiRef.current.fullpageApi.moveTo(link+1)
	}

	return (
		<ContainerBox>
			<Header t={t} onClick={onClick} currentPage={currentPage} />
				<div id="hero" className="ctm-flp">
					<Hero t={t} className="ctm-flp" />
				</div>
				<div id="story" className="ctm-flp">
					<Story t={t} />
				</div>
				<div id="services" className="ctm-flp">
					<Services t={t} />
				</div>
				<div id="clients" className="ctm-flp">
					<Clients t={t} />
				</div>
				<div id="stats" className="ctm-flp">
					<Stats t={t} />
				</div>
				{/* <div id="team" className="ctm-flp">
					<Team t={t} />
				</div> */}
				<div id="contact" className="ctm-flp">
					<Contact t={t} />
				</div>
				<div id="form" className="ctm-flp">
					<Form t={t} />
				</div>

				<div id="footer" className="ctm-flp">
					<Footer t={t} />
				</div>
		</ContainerBox>
	);
};
