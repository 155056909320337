import styled from 'styled-components'

const ContactContainerBox = styled.div`
    width: 100vw;
    height: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f4f7fa;

    @media only screen and (max-width: 600px) {
        flex: 1;
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
`

const ItemTitle = styled.h3`
    text-align: left;
    padding: 0 10px;
`

const ItemDesc = styled.p`
    text-align: left;
`

export default {
    ContactContainerBox,
    ItemTitle,
    ItemDesc
}