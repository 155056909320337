import React from 'react';

// @styles
import styles from './Contact.styles';

const { ContactContainerBox, ItemTitle, ItemDesc } = styles;
export const LandingContact = ({t, ...props}) => {
	return (
		<ContactContainerBox className='bg-default-2'>
				<div className="inner-banner pt-29 pb-md-11 bg-default-2">
					<div className="container">
						<div className="row  justify-content-center pt-5">
							<div className="col-xl-8 col-lg-9">
								<div className="px-md-15 text-center">
									<h2 className="title gr-text-2 mb-9">{t('contact.title')}</h2>
									<p className="gr-text-8 mb-13">
										{t('contact.desc')}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className=" pb-5 pb-md-33 bg-default-2 ">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 mb-9">
								<div className="row">
									<div className="col-md-5 col-lg-4  mb-13">
										<div className="single-contact-widget d-flex">
											<div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
												<i className="icon icon-phone-2" />
											</div>
											<div className="widget-text">
												<ItemTitle className="gr-text-6 mb-5">{t('contact.call')}</ItemTitle>
												<ItemDesc className="gr-text-7 mb-0">
													920027026 <br />
													0118177501
												</ItemDesc>
											</div>
										</div>
									</div>
									<div className="col-md-5 col-lg-4 mb-13">
										<div className="single-contact-widget d-flex">
											<div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
												<i className="icon icon-email-83" />
											</div>
											<div className="widget-text">
												<ItemTitle className="gr-text-6 mb-5">{t('contact.email')}</ItemTitle>
												<ItemDesc className="gr-text-7 mb-0">
													info@soc.sa<br />
													support@soc.sa
												</ItemDesc>
											</div>
										</div>
									</div>
									<div className="col-md-5 col-lg-4 mb-13">
										<div className="single-contact-widget d-flex">
											<div className="widget-icon circle-sm-2 bg-white gr-text-6 text-primary mr-7">
												<i className="icon icon-pin-3-2" />
											</div>
											<div className="widget-text">
												<ItemTitle className="gr-text-6 mb-5">{t('contact.visit')}</ItemTitle>
												<ItemDesc className="gr-text-7 mb-0">
													{t('contact.address')}
												</ItemDesc>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
		</ContactContainerBox>
	);
};
