import React from 'react'

import Styled from 'styled-components'

import { screen } from '@styles/mixins'

const screenPhone = screen("phone")

const StyledSpacer = Styled.div`
  margin-inline-start: ${({ x }) => x ? `${x}rem` : 0};
  margin-block-start: ${({ y }) => y ? `${y}rem` : 0};
`

export const Spacer = ({x,y}) => {
    return (
        <StyledSpacer x={x} y={y}/>
    )
}