import produce from 'immer';
import { CONFIG_MINIMIZE_MENU, CONFIG_TOGGLE_THEME, CONFIG_DEFAULT_LANG } from './config.types';

const INITIAL_STATE = {
	minimized: true,
	theme: 'light',
	applang: 'en'
};

export default function configReducer(state = INITIAL_STATE, action) {
	const { type, payload } = action;
	return produce(state, (draft) => {
		switch (type) {
			case CONFIG_MINIMIZE_MENU: {
				draft.minimized = !draft.minimized;
				return draft;
			}

			case CONFIG_TOGGLE_THEME: {
				const { theme } = payload;
				draft.theme = theme;
				return draft;
			}

			case CONFIG_DEFAULT_LANG: {
				const { applang } = payload;
				draft.applang = applang;
				return draft;
			}

			default:
				return draft;
		}
	});
}
