const en = {
    header: {
        home: 'Home',
        story: 'Our Story',
        services: 'Services',
        partners: 'Partners',
        stats: 'Stats',
        team: 'Team',
        contact: 'Contact Us'
    },
    hero: {
        title: 'Need a web app to your business',
        desc: 'We aim to be leaders in the field of technology, our ambition leads us towards excellence and leadership. We provide our customers with unique and innovative solutions in developing programs, mobile applications and digital platforms, technical support, e-marketing, e-commerce, web design and website development.',
        watch: 'Watch in action'
    },
    story: {
        title: 'Our story',
        desc: "A Saudi company founded in 2016 and specialized in information technology and developing mobile applications and websites. We provide a wide range of our digital solutions in many fields, through various and flexible business models that suit our customers' desire, according to what achieves their desires and fulfills their ambitions",
        p1: 'We share common trends and strategies for improving your rental income and making sure you stay in high demand of service. ',
        p2: 'We convert your strategies into software systems and technology processes that optimize your business performance.',
        header: 'We are here to help the customers to get their success.'
    },
    services: {
        title: 'Services we offer for you',
        desc: 'We create user friendly professional user interfaces with high quality.',
        t1: 'User experience design and analysis',
        d1: 'Our designers and engineers have a way of finding solutions to your problem and they will use the best methods in developing your project and creating an exceptional user experience for your clients.',
        t2: 'Mobile application developmen',
        d2: 'We design and develop applications that attract the targeted customers and audience, and our team works to create applications of the highest quality and serve all customers on various modern devices and systems',
        t3: 'Websites development',
        d3: 'We provide professional websites development services and create professional designs to ensure attracting the largest number of customers to your website',
        t4: 'Websites development',
        d4: 'We provide professional websites development services and create professional designs to ensure attracting the largest number of customers to your website',
        t5: 'Digital Marketing / Content Management',
        d5: 'We use creative content and paid ads to increase awareness of your product and brand, as well as professional social media marketing to acquire and maintain customers',
        t6: ' Design',
        d6: 'We provide design services for digital marketing, logo design, fixed and animated advertising designs',

    },
    clients: {
        title: 'Big companies are here',
        desc: 'Our company assists any customers at any phase of business or software development — from startups at the ideation stage to mid-sized businesses and large enterprises with a focus on optimization and expansion no matter the industry they operate in',
    },
    stats: {
        title: 'Experienced experts are giving advices.',
        desc: 'We delivered a platform to collect user behavior information, build predictive models and help making informed business decisions.',
        p1: 'Customers visit us every month to get their service done via our website.',
        p2: 'Satisfaction rate comes from our awesome customers.',
        p3: 'Average customer ratings we have.',
        m: '+100k',
    },
    team: {
        title: 'Meet our team',
        desc: 'With lots of unique blocks, you can easily build a page without coding. Build your next consultancy website within few minutes.',
        m1: 'Raeed Habbab',
        mr1: 'Ceo & Founder',
        m2: 'Faisal',
        mr2: 'Founder',
        m3: 'Malik',
        mr3: 'Project Management',
        m4: 'Muhamed Ali',
        mr4: 'Manager',
    },
    contact: {
        title: 'Contact us',
        desc: "Your questions and comments are important to us, We would be happy to answer your questions or you can reach us by phone or email.\n We've got everything covered for your needs ",
        visit: 'Visit us',
        address: 'Prince Turkey Al-Awal,\n Riyadh, Saudi Arabia Kingdom',
        email: 'Email us',
        call: 'Call us',
    },
    form: {
        f_l_name: 'First & Last Name',
        f_l_name_h: 'i.e. Muhhamed Ahmed',
        email: 'Email',
        email_h: 'i.e. info@sair.sa',
        mobile: 'Phone Number',
        mobile_h: 'i.e. +966567890123',
        subj: 'Subject',
        subj_h: 'i.e. Question about ...',
        msg: 'Message',
        msg_h: 'i.e message details ...',
        send: 'Send'
    },
    footer: {
        desc: 'Security Option Company is a management and technology consulting company that helps companies make improvements through technology– especially in the area of business management software.',
        comp: 'Company',
        about: 'About us',
        contact: 'Contact us',
        carrers: 'Careers',
        press: 'Press',
        product: 'Product',
        features: 'Features',
        pricing: 'Pricing',
        news: 'News',
        help: 'Help desk',
        support: 'Support',
        services: 'Services',
        digital: 'Digital Marketing',
        content: 'Content Writing',
        seo: 'SEO for Business',
        ui: 'UI Design',
        legal: 'Legal',
        privacy: 'Privacy Policy',
        terms: 'Terms & Conditions',
        return: 'Return Policy',
    }
}

export default en;