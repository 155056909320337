import React from 'react';

import { connect } from 'react-redux'
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

import Localizer from './Localizer';
// @pages
import { LandingPage } from '@pages';
const AppContainer = (props) => {
	return (
		<div style={{ backgroundColor: '#FFF' }}>
			<Router>
				<Localizer>
					<Switch>
						<Route exact path="/" localize={false} component={() => <Redirect to={`/${props.locale}`} />} />
                        <Route path='/' component={withNamespaces()(LandingPage)} />
					</Switch>
				</Localizer>
			</Router>
		</div>
	);
};

const mapProps = state => {
    return {
        locale: state.locale.locale
    }
}
export default connect(mapProps)(AppContainer);
