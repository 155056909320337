import styled from 'styled-components'

const HeroContainerBox = styled.div`
    /* style={{ marginTop: '75px', width: '100vw', backgroundColor: '#ccc' }} */
    /* padding-top: 75px; */
    width: 100vw;
    background-image: linear-gradient(to right, #09a8ac, #28b485);
    /*height: 100vh;*/
    /* max-height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* -webkit-clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%); */
    @media only screen and (max-width: 600px) {
        flex: 1;
        display: flex;
        width: 100%;
        height: auto;
        /* max-height: auto; */
    }
`

const ImageBox = styled.div`
    position: absolute;
    top: 0px;
    right: -38px;
    z-index: -1;

    @media only screen and (max-width: 600px) {
        right: 0;
        border: none;
    }
`

const BannerImage = styled.img`
    height: 340px;
    margin: 40px 0;
    z-index: 1
`
const Image = styled.img`
    width: 100px;
    height: 100px;
    z-index: -1;
`

export default {
    HeroContainerBox,
    ImageBox,
    BannerImage,
    Image
}